import './trainingcontainer.css';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Training() {
    return (
        <div className="trainingcontainer" id="training-section">
            <h3 style={{ textAlign: 'center', color: 'orangered' }}>Corporate Training</h3>
            <Row className="justify-content-around" style={{ marginTop: 50 }}>
                <Col xs={12} md={4} lg={4}>
                    <div className="horizontal-div">
                        <div className="d-flex flex-wrap justify-content-around">
                            <div>
                                <h4 style={{ color: 'darkorange' }}>Java Full Stack Training</h4>
                            </div>
                            <div>
                                <p style={{ textAlign: 'justify' }}>
                                    Java Full Stack Development involves creating complete web applications using Java
                                    technologies for both front-end and back-end development.
                                    <br />
                                    <b>Front-end Development:</b> HTML/CSS, JavaScript, React, and Bootstrap
                                    <br />
                                    <b>Back-end Development:</b> Java8, Spring Framework, SpringBoot etc.
                                </p>
                            </div>
                            <div>
                                <Link to="/training/java-fullstack">
                                    <button className="complete-course-btn">
                                        Complete Course
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={4} lg={4}>
                    <div className="horizontal-div">
                        <div className="d-flex flex-wrap justify-content-around">
                            <div>
                                <h4 style={{ color: 'darkorange' }}>Springboot and Microservices</h4>
                            </div>
                            <div>
                                <p style={{ textAlign: 'justify' }}>
                                    Spring Boot is a framework that simplifies the development of Java-based applications by providing pre-configured setups and boilerplate code.
                                    <br />
                                    <b>SpringBoot Development:</b> Spring Initializr, Spring Boot CLI, Annotations, REST API etc.
                                    <br />
                                    <b>Microservices:</b> SpringCloud, Service Discovery, API Gateway, Configuration Management etc.
                                </p>
                            </div>
                            <div>
                                <Link to="/training/springboot-microservices">
                                    <button className="complete-course-btn">
                                        Complete Course
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={4} lg={4}>
                    <div className="horizontal-div">
                        <div className="d-flex flex-wrap justify-content-around">
                            <div>
                                <h4 style={{ color: 'darkorange' }}>MERN Stack Training</h4>
                            </div>
                            <div>
                                <p style={{ textAlign: 'justify' }}>
                                    <b>MongoDB:</b> NoSQL database that stores data in JSON-like documents.
                                    <br />
                                    <b>Express:</b> Lightweight web application framework for Node.js
                                    <br />
                                    <b>React:</b> Used for building user interfaces, especially single-page applications (SPAs).
                                    <br />
                                    <b>Node:</b> Enables server-side scripting with JavaScript.
                                </p>
                            </div>
                            <div>
                                <Link to="/training/mern-stack">
                                    <button className="complete-course-btn">
                                        Complete Course
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="justify-content-around" style={{ marginTop:10 }}>
                <Col xs={12} md={4} lg={4}>
                    <div className="horizontal-div">
                        <div className="d-flex flex-wrap justify-content-around">
                            <div>
                                <h4 style={{ color: 'darkorange' }}>Java Development using Java8</h4>
                            </div>
                            <div>
                                <p style={{ textAlign: 'justify' }}>
                                    Java Full Stack Development involves creating complete web applications using Java
                                    technologies for both front-end and back-end development.
                                    <br />
                                    <b>Front-end Development:</b> HTML/CSS, JavaScript, React, and Bootstrap
                                    <br />
                                    <b>Back-end Development:</b> Java8, Spring Framework, SpringBoot etc.
                                </p>
                            </div>
                            <div>
                                <Link to="/training/java8oops">
                                    <button className="complete-course-btn">
                                        Complete Course
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={4} lg={4}>
                    <div className="horizontal-div">
                        <div className="d-flex flex-wrap justify-content-around">
                            <div>
                                <h4 style={{ color: 'darkorange' }}>Oracle SQL</h4>
                            </div>
                            <div>
                                <p style={{ textAlign: 'justify' }}>
                                    Spring Boot is a framework that simplifies the development of Java-based applications by providing pre-configured setups and boilerplate code.
                                    <br />
                                    <b>SpringBoot Development:</b> Spring Initializr, Spring Boot CLI, Annotations, REST API etc.
                                    <br />
                                    <b>Microservices:</b> SpringCloud, Service Discovery, API Gateway, Configuration Management etc.
                                </p>
                            </div>
                            <div>
                                <Link to="/training/oraclesql">
                                    <button className="complete-course-btn">
                                        Complete Course
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={4} lg={4}>
                    <div className="horizontal-div">
                        <div className="d-flex flex-wrap justify-content-around">
                            <div>
                                <h4 style={{ color: 'darkorange' }}>Oracle PL/SQL</h4>
                            </div>
                            <div>
                                <p style={{ textAlign: 'justify' }}>
                                    <b>MongoDB:</b> NoSQL database that stores data in JSON-like documents.
                                    <br />
                                    <b>Express:</b> Lightweight web application framework for Node.js
                                    <br />
                                    <b>React:</b> Used for building user interfaces, especially single-page applications (SPAs).
                                    <br />
                                    <b>Node:</b> Enables server-side scripting with JavaScript.
                                </p>
                            </div>
                            <div>
                                <Link to="/training/plsql">
                                    <button className="complete-course-btn">
                                        Complete Course
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Training;
