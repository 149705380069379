import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import { Row, Col, Container } from 'react-bootstrap';
import HeaderMenu from './HeaderMenu';
import TrainingRoutes from './TrainingRoutes';
import ClientList from './ClientList';
import AboutUs from './AboutUs';
import Footer from './Footer';
import ImageSlide from './ImageSlide';

import frankfinn from './images/frankfinn.jpg';
import mudra from './images/mudra.png';
import scadea from './images/scadea.png';
import ienergizer from './images/ienergizer.png';
import paytm from './images/paytm.png';
import ScrolltotopButton from './ScrolltotopButton';
function App() {
    const images = [
        frankfinn,
        mudra,
        scadea,
        ienergizer,
        paytm,
    ];

    return (
        <Container className="main" fluid>
            <Row className="align-items-center no-gutters">
                <Col xs="12">
                    <HeaderMenu />
                </Col>
            </Row>
            <Row className="align-items-center no-gutters image-slide-row">
                <Col xs="12">
                    <ImageSlide />
                </Col>
            </Row>
            <Row className="align-items-center no-gutters" style={{ backgroundColor: '#ded6d5' }}>
                <Col>
                    <TrainingRoutes />
                </Col>
            </Row>
            <Row className="align-items-center no-gutters" style={{ backgroundColor: '#5e3532' }}>
                <Col>
                    <ClientList images={images} />
                </Col>
            </Row>
            <Row className="align-items-center no-gutters" style={{ backgroundColor: '#ded6d5' }}>
                <Col>
                    <AboutUs />
                </Col>
            </Row>
            <Row className="align-items-center no-gutters">
                <Col>
                    <Footer /><ScrolltotopButton />
                </Col>

                
                    
                
            </Row>
        </Container>
    );
}

export default App;
