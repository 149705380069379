import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const ScrollerWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  @media (max-width: 576px) {
    padding: 10px 0;
  }
`;

const ImageWrapper = styled.div`
  display: inline-block;
  margin: 0 10px;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.1);
  }
`;

const Image = styled.img`
  width: 200px;
  height: 100px;
  object-fit: fill;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 150px;
    height: 75px;
  }

  @media (max-width: 576px) {
    width: 100px;
    height: 50px;
  }
`;

const ClientList = ({ images }) => {
    const scrollerRef = useRef(null);
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        const scrollImages = () => {
            if (!isPaused && scrollerRef.current) {
                scrollerRef.current.scrollLeft += 1;
                if (scrollerRef.current.scrollLeft >= scrollerRef.current.scrollWidth - scrollerRef.current.clientWidth) {
                    scrollerRef.current.scrollLeft = 0;
                }
            }
        };

        const interval = setInterval(scrollImages, 30);

        return () => clearInterval(interval);
    }, [isPaused]);

    return (
        <div id="clients">
            <center><h2 style={{ color: 'white' }}>Our Clients</h2></center>
            <ScrollerWrapper ref={scrollerRef}>
                {images.map((src, index) => (
                    <ImageWrapper
                        key={index}
                        onMouseEnter={() => setIsPaused(true)}
                        onMouseLeave={() => setIsPaused(false)}
                    >
                        <Image src={src} alt={`client-${index}`} />
                    </ImageWrapper>
                ))}
            </ScrollerWrapper>
        </div>
    );
};

export default ClientList;
