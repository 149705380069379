import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav } from 'react-bootstrap';
import logo from './images/logo.png';
import './Navbar.css';
import React, { useState, useEffect } from 'react';

function HeaderMenu() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleScrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            const yOffset = -70; // Adjust this value based on your header height
            const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: yPosition, behavior: 'smooth' });
            setMenuOpen(false); // Close the menu after scrolling
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div id='home'>
        <Navbar expand="md" className={`fixed-top navbar-custom ${scrolled ? 'navbar-scrolled' : ''}`} expanded={menuOpen}>
            <Navbar.Brand href="#">
                <img className="navbar-logo" src={logo} alt="Company Logo" />
                <h6>VolitionSphere IT Services</h6>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleMenu} />
            <Navbar.Collapse id="basic-navbar-nav" className={`menu-collapse ${menuOpen ? 'show' : ''}`}>
                <Nav className="ml-auto">
                    <Nav.Link onClick={() => handleScrollToSection('home')} className="nav-link-custom nav-link-home">Home</Nav.Link>
                    <Nav.Link onClick={() => handleScrollToSection('clients')} className="nav-link-custom">Our Clients</Nav.Link>
                    <Nav.Link onClick={() => handleScrollToSection('training-section')} className="nav-link-custom">Training</Nav.Link>
                    <Nav.Link onClick={() => handleScrollToSection('aboutus-section')} className="nav-link-custom">About Us</Nav.Link>
                    <Nav.Link onClick={() => handleScrollToSection('contact-us-section')} className="nav-link-custom">Contact Us</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </Navbar>
        </div>
    );
}

export default HeaderMenu;
